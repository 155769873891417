html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-family: 'Sansation';
  overflow-x: hidden;
}
body.no-scroll {
  overflow: hidden;
}
body,
html {
  font-size: 10px;
  position: relative;
  height: 100%;
}
.mobile {
  overflow-x: hidden;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
  font-family: inherit;
}
*:before {
  box-sizing: border-box;
}
*:after {
  box-sizing: border-box;
}
*:active,
*:focus {
  outline: 0;
}
b,
strong {
  font-weight: 700;
}
a {
  text-decoration: none !important;
  color: inherit;
}
img {
  max-width: 100%;
}
button {
  cursor: pointer;
}
.oldiewarning {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #32363b;
  overflow: hidden;
  z-index: 1000;
  display: none;
}
.oldiewarning p,
.oldiewarning a {
  width: 100%;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 500px;
  color: #fff;
  font-size: 40px;
  font-family: Tahoma, Geneva, sans-serif;
}
.lt-ie9 {
  overflow: hidden;
}
.lt-ie9 .oldiewarning {
  display: block;
}
.sr-only {
  position: absolute;
  visiblity: hidden;
  top: -9999px;
  left: -9999px;
  pointer-events: none;
}
@font-face {
  font-family: 'Sansation';
  src: url("../fonts/Sansation-Regular.ttf") format('truetype');
  font-weight: 'normal';
  font-style: normal;
}
@font-face {
  font-family: 'Sansation';
  src: url("../fonts/Sansation-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Al Fresco';
  src: url("../fonts/Al-Fresco.ttf") format('truetype');
  font-weight: 'normal';
  font-style: normal;
}
.section-title {
  text-transform: uppercase;
}
@media screen and (max-width: 599px) {
  .section-title {
    font-size: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .section-title {
    font-size: 28px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .section-title {
    font-size: 32px;
  }
}
@media screen and (min-width: 1400px) {
  .section-title {
    font-size: 40px;
  }
}
.section-title.gray {
  color: #58595b;
}
.section-title .bigger {
  display: block;
  font-weight: bold;
}
@media screen and (max-width: 599px) {
  .section-title .bigger {
    font-size: 30px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .section-title .bigger {
    font-size: 36px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .section-title .bigger {
    font-size: 50px;
  }
}
@media screen and (min-width: 1400px) {
  .section-title .bigger {
    font-size: 68px;
  }
}
.section-title .bigger.green {
  color: #96c14e;
}
.section-title .bigger.white {
  color: #fff;
}
@media screen and (min-width: 1025px) {
  .section-title .break {
    font-size: 26px;
    display: block;
  }
}
.section-title.smaller-margin {
  margin-bottom: 15px;
}
@media screen and (min-width: 1400px) and (max-width: 1605px) {
  .section-title.smaller-margin {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1605px) {
  .section-title.smaller-margin {
    margin-bottom: 30px;
  }
}
.section-title.medium-margin {
  margin-bottom: 30px;
}
@media screen and (min-width: 1400px) and (max-width: 1605px) {
  .section-title.medium-margin {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1605px) {
  .section-title.medium-margin {
    margin-bottom: 50px;
  }
}
.section-title.margin {
  margin-bottom: 40px;
}
@media screen and (min-width: 1400px) and (max-width: 1605px) {
  .section-title.margin {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 1605px) {
  .section-title.margin {
    margin-bottom: 65px;
  }
}
@media screen and (min-width: 1025px) {
  .section-title.has-shaded-copy:after {
    content: attr(data-shade);
    display: block;
    position: absolute;
    color: rgba(0,0,0,0.02);
    top: 0;
    font-family: 'Al Fresco';
    pointer-events: none;
    text-transform: initial;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .section-title.has-shaded-copy:after {
    font-size: 270px;
  }
}
@media screen and (min-width: 1400px) {
  .section-title.has-shaded-copy:after {
    font-size: 442px;
  }
}
.generic-text.smallest {
  font-size: 12px;
  line-height: 16px;
}
.generic-text.smaller {
  font-size: 14px;
}
@media screen and (max-width: 599px) {
  .generic-text.regular {
    font-size: 12px;
    line-height: 16px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .generic-text.regular {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1025px) {
  .generic-text.regular {
    font-size: 18px;
    line-height: 22px;
  }
}
.generic-text.bigger {
  font-size: 20px;
}
.generic-text.biggest {
  font-size: 24px;
}
.generic-text.gray {
  color: #58595b;
}
.generic-text.light-gray {
  color: #808285;
}
.generic-text.white {
  color: #fff;
}
@media screen and (min-width: 1025px) {
  .generic-text.smaller-limited-width {
    width: 400px;
  }
}
@media screen and (max-width: 599px) {
  .generic-text.limited-width {
    max-width: 260px;
  }
}
@media screen and (min-width: 1025px) {
  .generic-text.limited-width {
    width: 555px;
  }
}
@media screen and (max-width: 1025px) {
  .generic-text.is-paragraph {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 1025px) {
  .generic-text.is-paragraph {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1025px) {
  .generic-text.small-paragraph {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 1025px) {
  .generic-text.small-paragraph {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1025px) {
  .generic-text.smallest-paragraph {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1025px) {
  .generic-text.smallest-paragraph {
    margin-bottom: 20px;
  }
}
.schedule-cta {
  background-color: #96c14e;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 2px 0 rgba(0,0,0,0.15);
}
.schedule-cta.mobile-cta {
  box-shadow: 0 -6px 0 #55791d, 0 -9px 0 rgba(0,0,0,0.1);
  width: 100%;
  height: 42px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 7;
  font-size: 14px;
  border: 0;
  width: 50%;
  left: 0;
  text-align: center;
}
@media screen and (min-width: 1025px) {
  .schedule-cta.mobile-cta {
    display: none !important;
  }
}
.schedule-cta.mobile-cta.zap {
  right: 0;
  left: auto;
  line-height: 40px;
  font-size: 12px;
  border-left: 2px solid rgba(255,255,255,0.2);
  display: block;
}
.schedule-cta:not(.mobile-cta) {
  line-height: 16px;
  border: 1px solid transparent;
  font-size: 15px;
  height: 46px;
  box-shadow: 0 6px 0 #55791d, 0 9px 0 rgba(0,0,0,0.1);
  border-radius: 70px;
  width: 190px;
}
.know-more {
  background-color: #fff;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  color: #58595b;
  height: 40px;
  width: 182px;
  box-shadow: 0 6px 0 #55791d, 0 9px 0 rgba(0,0,0,0.1);
  border: 3px solid #96c14e;
  line-height: 37px;
  text-align: center;
}
.know-more:hover {
  background-color: #73ab19;
  border-color: #fff;
  color: #fff;
}
.switch {
  padding: 10px 42px;
  font-size: 16px;
  color: #fff;
  background-color: #75ac1c;
  border: 3px solid #fff;
  box-shadow: 0 6px 0 #55791d, 0 9px 0 rgba(0,0,0,0.1);
  border-radius: 30px;
  font-weight: bold;
}
.switch:hover {
  background-color: #fff;
  color: #73ab19;
}
.blog-cta,
.submit-contact-form,
.modal-submit,
.submit-lp,
.full-submit,
.submit-fixed-form {
  width: 180px;
  font-size: 16px;
  background-color: #fff;
  border: 3px solid #96c14e;
  padding: 8px 0;
  text-align: center;
  box-shadow: 0 6px 0 #55791d, 0 9px 0 rgba(0,0,0,0.1);
  display: block;
  border-radius: 30px;
  font-weight: bold;
  color: #58595b;
}
.blog-cta:hover,
.submit-contact-form:hover,
.modal-submit:hover,
.submit-lp:hover,
.full-submit:hover,
.submit-fixed-form:hover {
  background-color: #96c14e;
  border-color: #fff;
  color: #fff;
}
.submit-contact-form {
  width: 130px;
  font-size: 20px;
  float: right;
  height: 34px;
  line-height: 30px;
  padding: 0;
  border-color: #fff;
  color: #fff;
  background-color: #73ab19;
}
.submit-contact-form:hover {
  background-color: #fff;
  color: #73ab19;
  border-color: #73ab19;
}
.modal-submit,
.submit-lp,
.full-submit,
.submit-fixed-form {
  background-color: #73ab19;
  width: 90px;
  font-size: 14px;
  float: right;
  border-color: #fff;
  color: #fff;
}
.modal-submit:hover,
.submit-lp:hover,
.full-submit:hover,
.submit-fixed-form:hover {
  background-color: #fff;
  color: #73ab19;
  border-color: #73ab19;
}
.close-modal,
.close-fixed-form {
  position: absolute;
  top: 0;
  right: 15px;
  padding: 0;
  font-size: 68px;
  color: #58595b;
  font-weight: bold;
  border: 0;
  background-color: transparent;
  transition: color 0.2s ease-in-out;
  z-index: 2;
}
.close-modal:hover,
.close-fixed-form:hover {
  color: #73ab19;
}
.close-fixed-form {
  font-size: 40px;
}
.submit-lp {
  margin-top: 35px;
  float: none;
  display: block;
  margin-left: auto;
}
.center-content {
  width: 100%;
  margin: auto;
  max-width: 1300px;
  z-index: 2;
}
@media screen and (min-width: 1025px) {
  .center-content:not(.inner) {
    padding: 0 30px;
  }
}
.center-content:not(.not-relative) {
  position: relative;
}
@media screen and (max-width: 1025px) {
  .center-content:not(.nopadding) {
    padding: 0 15px;
  }
}
@media screen and (min-width: 1025px) {
  .center-content.inner {
    width: 1170px;
  }
}
.dib {
  display: inline-block;
  vertical-align: top;
}
.cleared:after {
  content: '';
  display: block;
  clear: both;
}
.transitioned-basic {
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 1025px) {
  .hideonmobile {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .full {
    display: block;
    width: 100%;
  }
}
@media screen and (min-width: 1025px) {
  .half {
    float: left;
    width: 47.5%;
  }
}
@media screen and (min-width: 1025px) {
  .half + .half {
    margin-left: 5%;
  }
}
@media screen and (min-width: 1025px) {
  .half-child {
    float: left;
    width: 47.5%;
  }
}
@media screen and (min-width: 1025px) {
  .half-child + .half-child:not(:nth-child(2n+3)) {
    margin-left: 5%;
  }
}
@media screen and (min-width: 1025px) {
  .one-third {
    float: left;
    width: 31.666%;
  }
}
@media screen and (min-width: 1025px) {
  .one-third + .one-third {
    margin-left: 2.5%;
  }
}
@media screen and (min-width: 1025px) {
  .one-third-child {
    float: left;
    width: 31.666%;
    display: block;
  }
}
@media screen and (min-width: 1025px) {
  .one-third-child + .one-third-child:not(:nth-child(3n+4)) {
    margin-left: 2.5%;
  }
}
@media screen and (min-width: 1025px) {
  .one-fourth-child {
    float: left;
    width: 23.75%;
    display: block;
  }
}
@media screen and (min-width: 1025px) {
  .one-fourth-child + .one-fourth-child:not(:nth-child(4n+5)) {
    margin-left: 1.666%;
  }
}
@media screen and (min-width: 1025px) {
  .has-sidebar {
    float: left;
    width: 65.83%;
  }
}
@media screen and (min-width: 1025px) {
  .has-sidebar.left {
    margin-left: 5%;
  }
}
@media screen and (min-width: 1025px) {
  .has-sidebar.right {
    margin-right: 5%;
  }
}
.circled-borders {
  position: absolute;
  top: 50%;
  transform: translateY(-53%);
  left: 0;
  right: 0;
  margin: auto;
}
.has-painted-bg {
  position: relative;
}
.painted-bg {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
@media screen and (max-width: 1025px) {
  .painted-bg {
    background-image: url("../images/backgrounds/bg-green-mobile.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 650px;
    top: 0;
  }
}
@media screen and (min-width: 1025px) {
  .painted-bg {
    background: url("../images/backgrounds/bg-green.jpg") no-repeat top center/100%;
    height: 1547px;
  }
}
@media screen and (min-width: 1025px) {
  .painted-bg.experimental {
    background: url("../images/backgrounds/experimental.jpg") no-repeat top center/100%;
  }
}
.breadcrumb {
  border: 2px solid #96c14e;
  color: #58595b;
  background-color: #fff;
  font-size: 16px;
  border-radius: 20px;
}
@media screen and (max-width: 599px) {
  .breadcrumb {
    top: -350px;
  }
}
@media screen and (max-width: 1025px) {
  .breadcrumb {
    width: 280px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    padding: 5px 0;
    text-align: center;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .breadcrumb {
    top: -820px;
  }
}
@media screen and (min-width: 1025px) {
  .breadcrumb {
    padding: 5px 80px 5px 16px;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1400px) {
  .breadcrumb {
    margin-left: -45px;
  }
}
.breadcrumb a:hover {
  color: #96c14e;
}
@media screen and (min-width: 1025px) {
  .abstracted-inner {
    padding-top: 250px;
  }
}
.mobile-helper-text {
  color: #000;
  font-size: 12px;
  margin-top: 30px;
  font-weight: bold;
}
@media screen and (min-width: 1025px) {
  .mobile-helper-text {
    display: none;
  }
}
.mobile-helper-text .fa {
  font-size: 14px;
}
.mobile-helper-text span {
  margin-left: 5px;
  margin-right: 5px;
}
.becomes-carousel-on-mobile .slick-slide {
  margin: 0 30px;
}
.becomes-carousel-on-mobile .slick-list {
  margin: 0 -30px;
}
.fades-in-on-scroll {
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: all 0.8s ease-in-out;
}
.fades-in-on-scroll.play {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.flexed {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1025px) {
  .flexed {
    flex-flow: column wrap;
  }
}
@media screen and (min-width: 1025px) {
  .flexed {
    flex-flow: row wrap;
  }
}
.flexed.default {
  align-items: center;
}
.flexed.start {
  align-items: start;
}
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.slick-dots {
  position: relative;
  display: block;
  margin-right: 0;
  margin-left: auto;
}
/*
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #73ab19;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear;
}
.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent;
}
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}
@media screen and (max-width: 1025px) {
  .base {
    text-align: center;
    background-image: url("../images/backgrounds/bg-green-footer-mobile.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    min-height: 1080px;
    margin-top: -290px;
    padding-top: 460px;
  }
}
@media screen and (min-width: 1025px) {
  .base {
    background-image: url("../images/backgrounds/bg-green-footer.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    min-height: 1080px;
    margin-top: -290px;
    padding-top: 460px;
  }
}
.base iframe {
  width: 100%;
  box-shadow: 0 6px 0 #55791d, 0 9px 0 rgba(0,0,0,0.1);
  margin-bottom: 25px;
  border-radius: 20px;
}
@media screen and (max-width: 1025px) {
  .base iframe {
    margin-top: 35px;
  }
}
.footer-logo {
  display: block;
  margin-bottom: 50px;
}
@media screen and (max-width: 1025px) {
  .footer-logo {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1025px) {
  .footer-column {
    float: left;
  }
}
@media screen and (max-width: 1025px) {
  .footer-column:first-of-type {
    margin-bottom: 35px;
  }
}
@media screen and (min-width: 1025px) {
  .footer-column:first-of-type {
    width: 23.5%;
  }
}
.footer-column:first-of-type .generic-text {
  line-height: 18px;
}
@media screen and (min-width: 1025px) {
  .footer-column:nth-of-type(2) {
    width: 25.64%;
    margin-left: 8.54%;
  }
}
@media screen and (max-width: 1025px) {
  .footer-column:nth-of-type(3) {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .footer-column:nth-of-type(3) {
    width: 13.24%;
    margin-left: 8.11%;
  }
}
@media screen and (max-width: 1025px) {
  .footer-column:last-of-type {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .footer-column:last-of-type {
    width: 15.81%;
    margin-left: 5.16%;
  }
}
@media screen and (min-width: 1025px) {
  .footer-column.lp {
    width: 300px;
  }
}
.footer-title {
  color: #fff;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 24px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.footer-title:before {
  content: '';
  display: block;
  width: 0;
  position: absolute;
  left: 0;
  bottom: -5px;
  height: 2px;
  background-color: #f2ca30;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
}
.footer-title:hover:before {
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.footer-sublinks:not(:last-child) {
  margin-bottom: 15px;
}
.footer-sublinks li {
  font-size: inherit;
  color: inherit;
  line-height: 18px;
}
.footer-sublinks li:hover {
  font-weight: bold;
}
.copyright {
  height: 60px;
  background-color: #fff;
  box-shadow: 0 -10px 0 rgba(255,255,255,0.15);
  position: relative;
}
@media screen and (max-width: 1025px) {
  .copyright {
    margin-top: 50px;
    padding-top: 20px;
    padding-bottom: 200px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .copyright {
    margin-top: 60px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1605px) {
  .copyright {
    margin-top: 75px;
  }
}
@media screen and (min-width: 1605px) {
  .copyright {
    margin-top: 90px;
  }
}
@media screen and (min-width: 1025px) {
  .copyright .center-content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }
}
.social-and-copyright .fa,
.social-and-copyright .fab,
.social-and-copyright .fas {
  font-size: 24px;
  color: #96c14e;
}
@media screen and (max-width: 1025px) {
  .social-and-copyright .fa,
  .social-and-copyright .fab,
  .social-and-copyright .fas {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1025px) {
  .social-and-copyright > a {
    display: inline-block;
    vertical-align: top;
  }
}
.social-and-copyright > a + a {
  margin-left: 5px;
}
@media screen and (max-width: 1025px) {
  .social-and-copyright .generic-text {
    margin-bottom: 20px;
    font-size: 12px;
    display: block;
  }
}
@media screen and (max-width: 1025px) {
  .social-and-copyright .generic-text b {
    display: block;
    font-size: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .social-and-copyright .generic-text {
    margin-left: 20px;
    padding-top: 6px;
  }
}
.footer-painted-bg {
  position: absolute;
  top: -80px;
  left: 0;
}
@media screen and (max-width: 1025px) {
  .signature-image {
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1025px) {
  .signature-image {
    margin-left: 15px;
    margin-bottom: -13px;
  }
}
.lp-page {
  position: relative;
}
.lp-page .copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
body:not(.loaded) .overlay .modal-wrapper {
  display: none;
}
body:not(.loaded) .overlay .alert {
  display: none;
}
.loaded .overlay {
  display: none;
}
.loaded .loader-wrap {
  opacity: 0;
}
.overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(150,193,78,0.9);
  height: 100%;
}
.modal-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.modal-wrapper:not(.is-open) {
  display: none !important;
}
.modal-background {
  margin: auto;
  width: 100%;
  border-radius: 15px;
  border: 3px solid #96c14e;
  box-shadow: 0 6px 0 #55791d, 0 9px 0 rgba(0,0,0,0.1);
  position: relative;
  background-color: #fff;
  background-image: url("../images/backgrounds/bg-white-middle.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
}
@media screen and (max-width: 1025px) {
  .modal-background {
    max-height: 100vh;
    padding: 50px 10px;
    overflow-y: scroll;
  }
}
@media screen and (min-width: 1025px) {
  .modal-background {
    width: 700px;
    padding: 40px 33px;
  }
}
@media screen and (min-width: 1025px) {
  .modal-background .generic-text {
    max-width: 400px;
  }
}
.modal-persona {
  position: absolute;
  right: -70px;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
}
@media screen and (max-width: 1025px) {
  .modal-persona {
    display: none;
  }
}
.alert {
  position: absolute;
  width: 320px;
  height: 320px;
  top: 50%;
  transform: translateY(-50%);
  padding: 130px 10px 20px 10px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #fff;
  border: 5px solid transparent;
  border-radius: 10px;
  text-align: center;
}
.alert.positive {
  border-color: #008000;
}
.alert.positive .generic-text {
  color: #008000;
}
.alert.negative {
  border-color: #f00;
}
.alert.negative .generic-text {
  color: #f00;
}
.alert:not(.is-active) {
  display: none;
}
.loader-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}
.spinner {
  animation: spin 2.5s ease-in-out infinite;
}
@-moz-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.field {
  border: 3px solid #96c14e;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 6px 0 #55791d, 0 9px 0 rgba(0,0,0,0.1);
  border-radius: 30px;
  transition: background-color 0.15s ease-in-out;
  color: rgba(0,0,0,0.5);
  font-size: 12px;
  resize: none;
}
.field:not(.bigger) {
  height: 40px;
  margin-bottom: 20px;
}
.field:focus,
.field:active {
  background-color: #f2f2f2;
}
.field-wrapper.full {
  clear: both;
}
@media screen and (min-width: 1025px) {
  .contact-basic {
    max-width: 645px;
  }
}
.contact-basic .field:not(.bigger) {
  padding: 5px 10px;
}
.contact-basic .field.bigger {
  height: 95px;
  padding: 10px;
  margin-bottom: 40px;
}
.contact-basic .field-description {
  margin-bottom: 8px;
}
.modal-form {
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 1025px) {
  .modal-form {
    max-width: 500px;
  }
}
.modal-form .field {
  padding: 2px 10px;
  border-radius: 18px;
}
.modal-form .field.bigger {
  height: 54px;
  margin-bottom: 20px;
}
.form-title {
  font-size: 18px;
  color: #58595b;
  margin-bottom: 22px;
}
.form-title .bigger {
  color: #96c14e;
  font-size: 32px;
  display: block;
  font-weight: bold;
}
@media screen and (min-width: 1025px) {
  .experimental-full {
    width: 510px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.experimental-full .field {
  padding: 5px 10px;
  border-radius: 20px;
}
.experimental-full .field.bigger {
  height: 55px;
  margin-bottom: 20px;
}
@media screen and (max-width: 1025px) {
  .fixed-form-wrapper {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .fixed-form-wrapper {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: -3px;
    transition: transform 0.35s ease-in-out;
    z-index: 6;
  }
}
.fixed-form-wrapper.lp {
  transform: translateY(-50%) translateX(100%);
}
.fixed-form-wrapper.scrolled {
  transform: translateY(-50%) translateX(100%);
}
.fixed-form-wrapper.clicked {
  transform: translateY(-50%) translateX(100%) !important;
}
.wrapper-whatsapp-cta {
  position: relative;
}
.fixed-form {
  width: 243px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-top: 32px;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 23px;
  border: 3px solid #73ab19;
  box-shadow: 1px 6px 0 #55791d, 1px 9px 0 rgba(0,0,0,0.1);
  background-color: #fff;
  background-image: url("../images/backgrounds/bg-white-middle.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 20px center;
}
.fixed-form .field {
  border-radius: 15px;
  padding: 5px 10px;
}
.fixed-form .field:not(.bigger) {
  margin-bottom: 10px;
  height: 30px;
}
.fixed-form .field.bigger {
  margin-bottom: 15px;
  height: 54px;
}
.fixed-form .fixed-form-title {
  font-size: 16px;
  color: #58595b;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.fixed-form .fixed-form-title span {
  font-size: 32px;
  color: #96c14e;
  display: block;
  font-weight: bold;
}
@media screen and (min-width: 1025px) {
  .becomes-fixed {
    position: fixed;
    width: 100%;
    z-index: 8;
    transition: background-color 0.2s ease-in-out;
  }
}
.becomes-fixed.scrolled {
  background-color: #fff;
}
@media screen and (max-width: 1025px) {
  .upper-partition {
    display: block;
    position: fixed;
    background-color: #fff;
    z-index: 8;
    width: 100%;
    overflow-x: scroll;
  }
}
@media screen and (min-width: 1025px) {
  .upper-partition {
    font-size: 0;
    height: 35px;
    box-shadow: 0 3px 0 rgba(0,0,0,0.05);
    position: relative;
    background-color: #fff;
  }
}
@media screen and (max-width: 1025px) {
  .upper-partition .center-content {
    width: 900px;
    overflow-x: scroll;
  }
}
.upper-partition .instance {
  font-size: 0;
  line-height: 35px;
  transition: transform 0.15s ease-in-out;
}
@media screen and (max-width: 1025px) {
  .upper-partition .instance + .instance {
    margin-left: 10px;
  }
}
@media screen and (min-width: 1025px) {
  .upper-partition .instance + .instance {
    margin-left: 22px;
  }
}
@media screen and (min-width: 1025px) {
  .upper-partition .instance.right {
    float: right;
  }
}
.upper-partition .instance a:hover {
  font-weight: bold;
  transform: scale(1.01);
}
.upper-partition .fa,
.upper-partition .fab,
.upper-partition .fas {
  font-size: 18px;
  line-height: 35px;
  color: #96c14e;
  margin-right: 11px;
}
.sticky-partition {
  width: 100%;
  left: 0;
  transition: transform 0.2s ease-in-out, background-color 0.15s ease-in-out;
}
@media screen and (max-width: 1025px) {
  .sticky-partition {
    z-index: 9;
    background-color: #fff;
    box-shadow: rgba(255,255,255,0.5) 0 6px 0, 0 6px 0 rgba(0,0,0,0.05);
    height: 70px;
    padding-top: 20px;
    text-align: center;
    position: fixed;
    width: 100%;
    margin-top: 30px;
    border-top: 2px solid rgba(88,89,91,0.2);
  }
}
@media screen and (min-width: 1025px) {
  .sticky-partition {
    top: 53px;
  }
}
@media screen and (max-width: 1025px) {
  .sticky-partition.is-open:after {
    opacity: 1;
    transform: scale(30);
  }
}
@media screen and (max-width: 1025px) {
  .sticky-partition:after {
    content: '';
    background-color: #73ab19;
    display: block;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    right: -15px;
    top: -15px;
    opacity: 0;
    transform: scale(1);
    transition: opacity 0.2s ease-in-out, transform 0.7s ease-in-out 0.15s;
  }
}
@media screen and (max-width: 1025px) {
  .logo-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
  }
}
@media screen and (min-width: 1025px) {
  .logo-wrap {
    width: 20%;
  }
}
@media screen and (max-width: 1025px) {
  .logo {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .logo {
    max-width: 260px;
  }
}
@media screen and (max-width: 1025px) {
  .logo-mobile,
  .logo-white-mobile {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1025px) {
  .logo-mobile,
  .logo-white-mobile {
    display: none;
  }
}
@media screen and (max-width: 1025px) {
  .header-navigation {
    position: absolute;
    top: 50px;
    width: 100%;
    left: 0;
    height: 0;
  }
}
@media screen and (min-width: 1025px) {
  .header-navigation {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width: 100%;
  }
}
.header-navigation:not(.is-open) .logo-white-mobile {
  opacity: 0;
}
.header-navigation:not(.is-open) .logo-mobile {
  opacity: 1;
}
@media screen and (max-width: 1025px) {
  .header-navigation.is-open {
    height: 100vh;
  }
}
.header-navigation.is-open .logo-mobile {
  opacity: 0;
  position: absolute;
}
.header-navigation.is-open .logo-white-mobile {
  opacity: 1;
}
@media screen and (max-width: 1025px) {
  .links {
    opacity: 0;
    pointer-events: none;
    height: 110vh;
    overflow-y: scroll;
    transition: opacity 0.2s ease-in-out 0.5s;
  }
}
@media screen and (min-width: 1025px) {
  .links {
    width: 73.53%;
    margin-left: 6.47%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}
.links .fa {
  font-size: 10px;
}
@media screen and (max-width: 1025px) {
  .links .fa {
    font-size: 14px;
    color: #58595b;
    position: absolute;
    right: 25px;
    top: 14px;
  }
}
.links.is-open {
  opacity: 1;
  pointer-events: initial;
}
.link {
  font-size: 15px;
  color: #58595b;
}
@media screen and (max-width: 1025px) {
  .link {
    background-color: #73ab19;
    line-height: 43px;
    font-size: 14px;
    color: #fff;
  }
}
@media screen and (min-width: 1025px) {
  .link:not(.schedule-button) {
    padding-top: 8px;
  }
}
.link.has-submenu {
  position: relative;
}
@media screen and (min-width: 1025px) {
  .link.has-submenu:hover .submenu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
@media screen and (max-width: 1025px) {
  .link.schedule-button {
    display: none;
  }
}
@media screen and (max-width: 1025px) {
  .link:not(:last-of-type) {
    border-bottom: 1px solid rgba(88,89,91,0.3);
  }
}
@media screen and (max-width: 1025px) {
  .submenu {
    line-height: 43px;
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .submenu {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 20px;
    padding-left: 5px;
    padding-right: 5px;
    box-shadow: 0 6px 0 #55791d, 0 9px 0 rgba(0,0,0,0.1);
    background-color: rgba(255,255,255,0.8);
    position: absolute;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: -55px;
    top: 55px;
    min-width: 200px;
    text-align: center;
    border: 3px solid #96c14e;
    border-radius: 25px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-25px);
    transition: all 0.3s ease-in-out;
  }
}
@media screen and (min-width: 1025px) {
  .submenu li {
    font-size: 14px;
    color: #58595b;
    line-height: 30px;
    border-bottom: 1px solid rgba(88,89,91,0.3);
  }
}
.submenu li:last-child {
  border: none;
}
@media screen and (max-width: 1025px) {
  .submenu li:nth-of-type(even) {
    background-color: #4d4d4f;
  }
}
@media screen and (max-width: 1025px) {
  .submenu li:nth-of-type(odd) {
    background-color: #58595b;
  }
}
.submenu li:hover {
  font-weight: bold;
}
@media screen and (min-width: 1025px) {
  .submenu:after,
  .submenu:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
}
@media screen and (min-width: 1025px) {
  .submenu:after {
    border-color: rgba(255,255,255,0);
    border-bottom-color: rgba(255,255,255,0.9);
    border-width: 10px;
    margin-left: -10px;
  }
}
@media screen and (min-width: 1025px) {
  .submenu:before {
    border-color: rgba(150,193,78,0);
    border-bottom-color: #96c14e;
    border-width: 14px;
    margin-left: -14px;
  }
}
.toggle-menu {
  position: absolute;
  top: -45px;
  right: 20px;
  background-color: transparent;
  border: none;
  display: block;
}
@media screen and (min-width: 1025px) {
  .toggle-menu {
    display: none;
  }
}
@media screen and (max-width: 1025px) {
  .home-page {
    padding-top: 70px;
  }
}
@media screen and (min-width: 1025px) {
  .home-page {
    padding-top: 215px;
  }
}
@media screen and (max-width: 1025px) {
  .opening {
    text-align: center;
    z-index: 5;
    position: relative;
    padding-top: 70px;
  }
}
@media screen and (min-width: 1025px) {
  .opening {
    min-height: 80vh;
  }
}
@media screen and (min-width: 1025px) {
  .opening .section-title {
    max-width: 380px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .opening .has-shaded-copy:after {
    left: -100px;
  }
}
@media screen and (min-width: 1400px) {
  .opening .has-shaded-copy:after {
    left: -350px;
  }
}
@media screen and (min-width: 1605px) {
  .opening .has-shaded-copy:after {
    left: -500px;
  }
}
@media screen and (min-width: 1025px) {
  .opening .inner {
    display: flex;
    flex-flow: row nowrap;
  }
}
@media screen and (max-width: 1025px) {
  .opening .slick-dots {
    display: none !important;
  }
}
@media screen and (min-width: 1025px) {
  .opening .slick-dots {
    position: absolute;
    right: -15px;
    bottom: 100px;
    z-index: 5;
  }
}
.opening .slick-dots li {
  margin-bottom: 10px;
}
.opening .slick-dots li button {
  color: transparent;
  display: block;
  height: 15px;
  width: 15px;
  background-color: #73ab19;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.opening .slick-dots li.slick-active button {
  background-color: #fff;
  border-color: #73ab19;
}
.opening .slick-list {
  overflow: visible;
}
@media screen and (min-width: 1025px) {
  .carousel-instances {
    width: 43%;
  }
}
.carousel-instances .instance {
  padding-bottom: 15px;
}
@media screen and (min-width: 1025px) {
  .carousel-instances-nav {
    width: 75%;
    position: absolute;
    right: 0;
  }
}
@media screen and (min-width: 1025px) {
  .carousel-instances-nav .instance-1 {
    text-align: right;
  }
}
@media screen and (min-width: 1025px) {
  .carousel-instances-nav .instance-1 img {
    max-width: 700px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .carousel-instances-nav .instance-1 img {
    margin-top: -60px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .carousel-instances-nav .instance-2 {
    margin-top: -120px;
  }
}
.opening-vector,
.contact .contact-vector {
  position: absolute;
  top: 0;
  right: 0;
}
.woman-opening {
  transition: all 1s ease-in-out;
}
@media screen and (max-width: 1025px) {
  .woman-opening {
    margin-top: 35px;
  }
}
@media screen and (max-width: 1025px) {
  .know-more-about-us {
    text-align: center;
    background-color: #73ab19;
    padding-top: 65px;
    z-index: 3;
  }
}
@media screen and (min-width: 1025px) {
  .know-more-about-us {
    font-size: 0;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .know-more-about-us {
    padding-top: 40px;
  }
}
@media screen and (min-width: 1400px) {
  .know-more-about-us {
    padding-top: 50px;
  }
}
@media screen and (min-width: 1605px) {
  .know-more-about-us {
    padding-top: 65px;
  }
}
@media screen and (min-width: 1025px) {
  .know-more-about-us .text-segment {
    width: 48%;
  }
}
@media screen and (max-width: 1025px) {
  .know-more-about-us .painted-bg {
    background-size: 100% 78%;
    height: 730px;
    top: -140px;
    z-index: -1;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .know-more-about-us .painted-bg {
    top: -280px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1605px) {
  .know-more-about-us .painted-bg {
    top: -320px;
  }
}
@media screen and (min-width: 1605px) {
  .know-more-about-us .painted-bg {
    top: -480px;
  }
}
@media screen and (max-width: 599px) {
  .know-more-about-us .generic-text {
    max-width: 245px;
  }
}
.switches {
  font-size: 0;
}
@media screen and (max-width: 1025px) {
  .switches {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .switches {
    width: 50%;
    margin-left: 2%;
  }
}
@media screen and (min-width: 1025px) {
  .switches .switch {
    margin-bottom: 30px;
  }
}
.switches .switch + .switch:not(:nth-of-type(2n+3)) {
  margin-left: 30px;
}
.for-who {
  position: relative;
}
@media screen and (max-width: 599px) {
  .for-who {
    padding-top: 350px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .for-who {
    padding-top: 500px;
  }
}
@media screen and (max-width: 1025px) {
  .for-who {
    text-align: center;
    z-index: 6;
    padding-bottom: 150px;
  }
}
@media screen and (min-width: 1025px) {
  .for-who {
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .for-who {
    padding-top: 160px;
  }
}
@media screen and (min-width: 1400px) {
  .for-who {
    padding-top: 260px;
  }
}
@media screen and (min-width: 1605px) {
  .for-who {
    padding-top: 360px;
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 1025px) {
  .for-who .limited-width {
    margin-left: auto;
    margin-right: auto;
  }
}
.woman-stretching {
  position: absolute;
  right: 0;
}
@media screen and (max-width: 1025px) {
  .woman-stretching {
    top: 30px;
    margin: auto;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .woman-stretching {
    max-width: 1000px;
    top: 30px;
  }
}
@media screen and (min-width: 1400px) {
  .woman-stretching {
    top: 100px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1605px) {
  .woman-stretching {
    max-width: 1200px;
  }
}
.for-who-instances {
  position: relative;
}
@media screen and (max-width: 1025px) {
  .for-who-instances {
    margin-top: 40px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .for-who-instances {
    margin-top: 110px;
  }
}
@media screen and (min-width: 1400px) {
  .for-who-instances {
    margin-top: 140px;
  }
}
@media screen and (min-width: 1605px) {
  .for-who-instances {
    margin-top: 165px;
  }
}
.for-who-image {
  border-radius: 50%;
}
.for-who-instance {
  font-size: 0;
}
@media screen and (max-width: 599px) {
  .for-who-instance {
    width: 320px;
    padding-top: 36px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .for-who-instance {
    padding-top: 50px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .for-who-instance {
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 1400px) {
  .for-who-instance {
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 1605px) {
  .for-who-instance {
    margin-bottom: 100px;
  }
}
.for-who-instance .picture-wrapper {
  position: relative;
}
@media screen and (max-width: 1025px) {
  .for-who-instance .picture-wrapper {
    display: block;
    margin-bottom: 35px;
  }
}
@media screen and (min-width: 1025px) {
  .for-who-instance .picture-wrapper {
    width: 54.28%;
    text-align: center;
  }
}
@media screen and (max-width: 1025px) {
  .for-who-instance .information-wrapper {
    display: block;
  }
}
@media screen and (min-width: 1025px) {
  .for-who-instance .information-wrapper {
    margin-left: 5.71%;
    width: 40.01%;
  }
}
.picture-wrapper:before {
  content: '';
  display: block;
  width: 69%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  background-color: rgba(0,0,0,0.5);
  z-index: 3;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.picture-wrapper:after {
  content: '+';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 40%;
  transform: translateY(-50%);
  font-size: 120px;
  color: #fff;
  z-index: 4;
  opacity: 0;
  transition: opacity 0.15s ease-in-out 0.15s;
}
.picture-wrapper:hover:before,
.picture-wrapper:hover:after {
  opacity: 1;
}
.for-who-instance-title,
.application-instance-title {
  margin-bottom: 25px;
  color: #000;
}
@media screen and (max-width: 1025px) {
  .for-who-instance-title,
  .application-instance-title {
    font-size: 26px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .for-who-instance-title,
  .application-instance-title {
    font-size: 22px;
  }
}
@media screen and (min-width: 1400px) {
  .for-who-instance-title,
  .application-instance-title {
    font-size: 26px;
  }
}
.for-who-instance-text {
  margin-bottom: 25px;
}
@media screen and (max-width: 599px) {
  .for-who-instance-text {
    max-width: 200px;
  }
}
.for-who-instance-link,
.application-instance-link {
  font-size: 14px;
  color: #96c14e;
  text-decoration: underline !important;
}
.for-who-instance-link:hover,
.application-instance-link:hover {
  color: #f2ca30;
}
@media screen and (max-width: 1025px) {
  .applications {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .applications {
    background: url("../images/backgrounds/bg-white-middle.jpg") no-repeat right center/contain;
  }
}
.applications .has-shaded-copy:after {
  font-size: 280px;
}
@media screen and (min-width: 1025px) {
  .applications .has-shaded-copy:after {
    top: -30px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .applications .has-shaded-copy:after {
    right: -150px;
  }
}
@media screen and (min-width: 1400px) {
  .applications .has-shaded-copy:after {
    right: -250px;
  }
}
@media screen and (min-width: 1605px) {
  .applications .has-shaded-copy:after {
    right: -350px;
  }
}
@media screen and (min-width: 1025px) {
  .application-instances {
    margin-top: 140px;
  }
}
.application-instances .picture-wrapper:before,
.application-instances .picture-wrapper:after {
  display: none;
}
.application-instance {
  font-size: 0;
}
@media screen and (min-width: 1025px) {
  .application-instance {
    margin-bottom: 50px;
    min-height: 170px;
  }
}
.application-instance .picture-wrapper {
  position: relative;
}
@media screen and (min-width: 1025px) {
  .application-instance .picture-wrapper {
    width: 32.69%;
    text-align: center;
  }
}
@media screen and (min-width: 1025px) {
  .application-instance .information-wrapper {
    margin-left: 3.85%;
    width: 63.46%;
  }
}
@media screen and (min-width: 1025px) {
  .application-instance-title {
    font-size: 20px !important;
  }
}
.device {
  position: absolute;
  right: 0;
  top: 0;
}
@media screen and (max-width: 599px) {
  .plans {
    padding-top: 160px;
  }
}
@media screen and (max-width: 1025px) {
  .plans {
    text-align: center;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .plans {
    padding-top: 260px;
  }
}
@media screen and (min-width: 1025px) {
  .plans {
    padding-bottom: 70px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .plans {
    padding-top: 70px;
  }
}
@media screen and (min-width: 1400px) {
  .plans {
    padding-top: 120px;
  }
}
@media screen and (min-width: 1605px) {
  .plans {
    padding-top: 60px;
  }
}
.plans .painted-bg {
  transform: scaleY(-1);
}
@media screen and (max-width: 599px) {
  .plans .painted-bg {
    top: 65px;
    height: 800px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .plans .painted-bg {
    top: 125px;
    height: 880px;
  }
}
@media screen and (max-width: 1025px) {
  .plans .painted-bg {
    background-size: 100% 99%;
  }
}
@media screen and (min-width: 1025px) {
  .plans .painted-bg {
    top: -256px;
    background-size: 100% 105%;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1605px) {
  .plans .painted-bg {
    top: -200px;
    background-size: 100% 110%;
  }
}
@media screen and (min-width: 1605px) {
  .plans .painted-bg {
    top: -230px;
    background-size: 100% 100%;
  }
}
@media screen and (max-width: 1025px) {
  .plans .limited-width {
    margin-left: auto;
    margin-right: auto;
  }
}
.yoga-pose {
  position: absolute;
}
@media screen and (max-width: 599px) {
  .yoga-pose {
    top: -210px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .yoga-pose {
    top: -310px;
  }
}
@media screen and (max-width: 1025px) {
  .yoga-pose {
    left: 0;
    right: 0;
    margin: auto;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .yoga-pose {
    right: 0;
    top: -95px;
  }
}
@media screen and (min-width: 1400px) {
  .yoga-pose {
    top: -30px;
    right: 40px;
  }
}
.diagram {
  display: block;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
}
@media screen and (max-width: 1025px) {
  .diagram {
    display: none;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .diagram {
    margin-top: 30px;
  }
}
@media screen and (min-width: 1400px) {
  .diagram {
    margin-top: 50px;
  }
}
.mobile-only-carousel {
  display: block;
  margin-top: 35px;
}
@media screen and (min-width: 1025px) {
  .mobile-only-carousel {
    display: none;
  }
}
.mobile-only-carousel .instance {
  padding-top: 10px;
}
.mobile-only-carousel .instance .generic-text {
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
}
.date-wrapper {
  background: url("../images/common/vector-white.png") no-repeat center center/cover;
  height: 150px;
  width: 150px;
  text-align: center;
  margin-bottom: 25px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 45px;
}
.date-wrapper .yellow {
  font-size: 50px;
  display: block;
  color: #f2ca30;
  font-weight: bold;
}
.date-wrapper .inside-vector {
  font-size: 9px;
}
.mobile-only-carousel-as-nav {
  margin-top: 35px;
}
@media screen and (min-width: 1025px) {
  .mobile-only-carousel-as-nav {
    display: none;
  }
}
@media screen and (max-width: 1025px) {
  .mobile-only-carousel-as-nav .instance {
    margin-top: 10px;
  }
}
.circle-text {
  color: #58595b;
  text-align: center;
  width: 50px;
  height: 50px;
  padding-top: 6px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background-color: #fff;
  margin-bottom: 25px;
  position: relative;
}
.circle-text:before {
  content: '';
  display: block;
  position: absolute;
  left: -5px;
  right: 0;
  margin: auto;
  top: 50%;
  width: 60px;
  height: 60px;
  transform: translateY(-50%);
  background-color: rgba(255,255,255,0.3);
  z-index: -1;
  border-radius: 50%;
}
.circle-text .bigger {
  font-size: 24px;
  display: block;
  font-weight: bold;
}
.circle-text .smaller {
  font-size: 11px;
}
.outer-text {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
.blog-feed {
  position: relative;
  z-index: 4;
}
@media screen and (max-width: 1025px) {
  .blog-feed {
    margin-top: 80px;
    text-align: center;
  }
}
@media screen and (max-width: 1025px) {
  .blog-feed .section-title {
    margin-bottom: 35px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .feed {
    margin-top: 80px;
  }
}
@media screen and (min-width: 1400px) {
  .feed {
    margin-top: 100px;
  }
}
@media screen and (min-width: 1605px) {
  .feed {
    margin-top: 130px;
  }
}
@media screen and (max-width: 1025px) {
  .feed .picture-wrapper,
  .feed .text-wrapper {
    display: block;
  }
}
.blog-thumbnail {
  border-radius: 50%;
}
@media screen and (min-width: 1025px) {
  .blog-thumbnail {
    width: 211px;
    height: 211px;
  }
}
.post-instance {
  font-size: 0;
}
@media screen and (max-width: 1025px) {
  .post-instance {
    width: 320px !important;
    margin: 55px 0 10px 0;
  }
}
@media screen and (min-width: 1025px) {
  .post-instance {
    margin-bottom: 100px;
  }
}
.post-instance .picture-wrapper {
  position: relative;
  text-align: center;
}
@media screen and (min-width: 1025px) {
  .post-instance .picture-wrapper {
    width: 49.15%;
  }
}
.post-instance .picture-wrapper:before {
  width: 75%;
}
@media screen and (min-width: 1025px) {
  .post-instance .text-wrapper {
    width: 47.45%;
    margin-left: 3.4%;
  }
}
@media screen and (max-width: 1025px) {
  .post-instance .blog-cta {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
@media screen and (max-width: 1025px) {
  .post-instance .blog-thumbnail {
    width: 240px;
    height: 250px;
  }
}
.post-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  color: #000;
  margin-bottom: 12px;
}
@media screen and (max-width: 1025px) {
  .post-title {
    margin-top: 35px;
  }
}
.post-information {
  margin-bottom: 25px;
}
.post-information-instance {
  overflow-x: hidden;
}
.post-information-instance .fa {
  font-size: 14px;
  color: #96c14e;
  margin-right: 5px;
}
.post-information-instance .text {
  font-size: 13px;
  color: #58595b;
}
.post-information-instance + .post-information-instance {
  margin-left: 8px;
}
.contact {
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 599px) {
  .contact {
    padding-top: 370px;
  }
}
@media screen and (max-width: 1025px) {
  .contact {
    margin-top: 30px;
    text-align: center;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .contact {
    padding-top: 655px;
  }
}
@media screen and (min-width: 1025px) {
  .contact {
    padding-top: 50px;
  }
}
@media screen and (min-width: 1605px) {
  .contact {
    padding-top: 70px;
  }
}
@media screen and (min-width: 1400px) {
  .contact .contact-vector {
    top: -190px;
  }
}
.man {
  position: absolute;
  top: 0;
}
@media screen and (max-width: 1025px) {
  .man {
    left: 0;
    right: -10px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .man {
    max-width: 800px;
    right: -75px;
  }
}
@media screen and (min-width: 1400px) {
  .man {
    right: 0;
  }
}
@media screen and (min-width: 1605px) {
  .man {
    top: 55px;
  }
}
@media screen and (max-width: 1025px) {
  .for-template-page {
    padding-top: 30px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .for-template-page {
    padding-top: 200px;
  }
}
@media screen and (min-width: 1400px) {
  .for-template-page {
    padding-top: 250px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .for-template-page .experimental {
    top: -200px;
  }
}
@media screen and (max-width: 599px) {
  .for-opening {
    padding-top: 435px;
  }
}
@media screen and (max-width: 1025px) {
  .for-opening {
    text-align: center;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .for-opening {
    padding-top: 935px;
    padding-bottom: 100px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .for-opening .generic-text.regular {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 15px;
  }
}
.for-opening .center-content {
  z-index: 4;
}
@media screen and (max-width: 1025px) {
  .for-opening .limited-width {
    margin-left: auto;
    margin-right: auto;
  }
}
@media small-desktop-minmax {
  .structure {
    padding-top: 130px;
  }
}
@media screen and (min-width: 1400px) {
  .structure {
    padding-top: 180px;
  }
}
.background-top {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
@media screen and (max-width: 1025px) {
  .background-top {
    top: 30px;
    left: -50%;
    max-width: 150%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .background-top {
    max-width: 1140px;
  }
}
@media screen and (max-width: 1025px) {
  .experimental-class {
    display: none;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .experimental-class {
    padding-top: 0px;
    padding-bottom: 150px;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1605px) {
  .experimental-class {
    padding-top: 100px;
    padding-bottom: 200px;
  }
}
@media screen and (min-width: 1605px) {
  .experimental-class {
    padding-bottom: 280px;
  }
}
.experimental-class .prop {
  position: absolute;
  bottom: -175px;
  right: 5px;
  pointer-events: none;
}
.experimental-class .painted-bg {
  top: -400px;
}
@media screen and (min-width: 1025px) {
  .skewed-title {
    width: 900px;
    display: block;
    margin: 0 auto 10px auto;
  }
}
.skewed-title .gray {
  font-size: 36px;
  text-align: left;
  color: #58595b;
  margin-left: 65px;
}
.skewed-title .white {
  font-size: 84px;
  text-align: center;
  color: #fff;
}
.skewed-title .yellow {
  font-size: 65px;
  text-align: right;
  color: #f2ca30;
  margin-right: 50px;
}
.skewed-title span {
  transform: rotate(-8deg) skewX(-8deg);
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  text-shadow: 0 5px 0 rgba(0,0,0,0.1);
}
@media screen and (min-width: 1025px) {
  .localization-page {
    padding-top: 105px;
  }
}
@media screen and (min-width: 1025px) {
  .localization-page .experimental-class {
    padding-top: 0;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .localization-page .experimental-class .painted-bg {
    top: -230px;
  }
}
#map-wrapper {
  position: relative;
}
.breadcrumb-wrapper {
  position: absolute;
  left: 0;
  top: 50px;
}
@media screen and (max-width: 1025px) {
  #map {
    height: 400px;
  }
}
@media screen and (min-width: 1025px) {
  #map {
    height: 460px;
    background-color: #96c14e;
  }
}
@media screen and (max-width: 599px) {
  .address-information {
    padding-top: 320px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .address-information {
    padding-top: 385px;
  }
}
@media screen and (max-width: 1025px) {
  .address-information {
    text-align: center;
    padding-bottom: 35px;
  }
}
@media screen and (min-width: 1025px) {
  .address-information {
    padding-bottom: 200px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1400px) {
  .address-information {
    padding-top: 50px;
  }
}
@media screen and (min-width: 1400px) {
  .address-information {
    padding-top: 65px;
  }
}
@media screen and (min-width: 1605px) {
  .address-information {
    padding-top: 75px;
  }
}
@media screen and (max-width: 1025px) {
  .address-information .section-title {
    padding-top: 40px;
  }
}
@media screen and (max-width: 1025px) {
  .address-information .limited-width {
    margin-left: auto;
    margin-right: auto;
  }
}
.woman-prop {
  position: absolute;
  right: 0;
}
@media screen and (max-width: 599px) {
  .woman-prop {
    top: -325px;
  }
}
@media screen and (min-width: 600px) and (max-width: 1025px) {
  .woman-prop {
    top: -460px;
  }
}
@media screen and (max-width: 1025px) {
  .woman-prop {
    left: 0;
    margin: auto;
  }
}
@media screen and (min-width: 1025px) {
  .woman-prop {
    top: -160px;
  }
}
@media screen and (min-width: 1025px) {
  .landing-page {
    padding-bottom: 290px;
  }
}
@media screen and (min-width: 1025px) {
  .information-wrapper {
    width: 700px;
  }
}
@media screen and (max-width: 1025px) {
  .form-container {
    margin-top: 50px;
    margin-bottom: -105px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1025px) {
  .form-container {
    width: 400px;
  }
}
@media screen and (max-width: 1025px) {
  .lp-header .sticky-partition {
    margin-top: 0;
  }
}
@media screen and (max-width: 1025px) {
  .lp-sec-wrapper {
    padding-top: 100px;
  }
}
.lpForm {
  padding: 40px 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 25px 0 rgba(0,0,0,0.15);
}
.lpForm .field {
  padding: 5px 10px;
}
.lpForm .bigger {
  min-height: 120px;
}
.form-title {
  color: #96c14e;
  font-size: 32px;
  line-height: 38px;
  font-weight: bold;
}
.form-title .gray {
  display: block;
}
.man-landing-page {
  position: absolute;
  right: -25px;
  top: -215px;
  z-index: 5;
  pointer-events: none;
}
@media screen and (max-width: 1025px) {
  .man-landing-page {
    display: none;
  }
}
@media screen and (min-width: 1279px) and (max-width: 1700px) {
  .man-landing-page {
    right: -290px;
    top: -170px;
  }
}
@media screen and (min-width: 998px) and (max-width: 1279px) {
  .man-landing-page {
    display: none;
  }
}
.contact-wrapper-lp {
  float: right;
}
@media screen and (max-width: 1025px) {
  .contact-wrapper-lp {
    display: none;
  }
}
.contact-wrapper-lp a {
  font-size: 22px;
  line-height: 26px;
  color: #96c14e;
  transition: all 0.2s ease;
}
.contact-wrapper-lp a + * {
  margin-top: 5px;
  margin-left: 15px;
}
.contact-wrapper-lp a:hover {
  transform: scale(1.05);
}
.contact-wrapper-lp a:hover .generic-text {
  color: inherit;
}
.contact-wrapper-lp a .generic-text {
  font-size: 28px;
  padding-top: 13px;
}
.contact-wrapper-lp a i {
  padding-top: 15px;
}
